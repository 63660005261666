import React from "react";
import Welcome from "../../components/Welcome/Welcome";
import styled from "styled-components";
import WhyUs2 from "../../components/WhyUs/WhyUs2";
import ContactUs from "../../components/ContactUs/ContactUs";
import Services from "../../components/Services/Services";
import Infos from "../../components/infos/Infos";

const Home = ({ Width }) => {
  const Hr = styled.hr`
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: white;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    &:before {
      content: "";
      background: linear-gradient(to right, transparent, #818078, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    width: 50%;
    margin: auto;
  `;

  return (
    <div>
      <Welcome Width={Width} />
      <Infos Width={Width} />
      <Services />
      <Hr />
      <WhyUs2 Width={Width} />
      <ContactUs Width={Width} />
    </div>
  );
};

export default Home;
