import React from "react";
import AboutUsImg from "./logo.png";
import MissionImg from "./mission.gif";
import ValuesImg from "./values.png";
import VisionImg from "./vision.png";
import styled from "styled-components";
import { Typography } from "@mui/material";

const Infos = ({ Width }) => {
  console.log(Width);
  const Hr = styled.hr`
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: white;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    &:before {
      content: "";
      background: linear-gradient(to right, transparent, #818078, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    width: 50%;
    margin: auto;
  `;

  let OurMissionText =
    "Notre mission est simple : Développer votre entreprise et assurer sa réussite en vous offrant des solutions Stratégiques, Innovantes et performantes.";
  let OurVisionText =
    "'Expand Your Horizon' notre slogan en commun pour améliorer votre performance et atteindre vos perspectives.";
  let ValuesText =
    "Nous croyons en une collaboration de long terme basée sur : L'engagement, l'éthique et la confidentialité.";
  let AboutUsText =
    "GOLDEN TECHNOLOGY est une ESN créée en 2020 par Said Amrani, spécialisée dans le Conseil et l'Assistance à Maîtrise d'Ouvrage, l'Informatique Décisionnelle/Big Data et l'Informatique Industrielle. Forte d'une expertise métier et technique, l'entreprise se distingue par sa capacité à sélectionner les meilleurs consultants pour garantir le succès des projets de ses clients. Ses valeurs de transparence, d'engagement et d'efficacité en font un partenaire de confiance pour accompagner les entreprises dans la création, la refonte et l'optimisation de leur système d'information.";
  let InfosList = [
    { title: "A propos de nous", text: AboutUsText, img: AboutUsImg },
    { title: "Notre Mission", text: OurMissionText, img: MissionImg },
    { title: "Notre Vision", text: OurVisionText, img: VisionImg },
    { title: "Nos Valeurs", text: ValuesText, img: ValuesImg },
  ];

  const Element = styled.div`
    display: flex;
    align-items: center;
    width: 80%;
    max-width: 960px;
    margin: 20px;
  `;

  const ElementText = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px;
    align-items: center;
  `;

  const ElementImage = styled.div`
    width: 60%;
    margin: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: ${(props) => (props.width ? (props.width * 200) / 1000 : 150)}px;
    min-height: 180px;
    max-height: 150px;
  `;

  const Content = ({ FlexDirection }) =>
    InfosList.map((info, index) => (
      <React.Fragment key={index}>
        <Element
          style={{
            flexDirection:
              FlexDirection === "row" && index % 2 === 1
                ? "row-reverse"
                : FlexDirection,
          }}
        >
          <ElementText>
            <Typography
              variant="h5"
              style={{ marginBottom: "10px", width: "100%" }}
            >
              {info.title}
            </Typography>
            <Typography variant="caption" paragraph={true}>
              &nbsp; {info.text} &nbsp;
            </Typography>
          </ElementText>
          <ElementImage
            width={Width}
            style={{ backgroundImage: `url("${info.img}")` }}
          />
        </Element>
        <Hr />
      </React.Fragment>
    ));
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  return (
    <Container>
      <Content FlexDirection={Width > 650 ? "row" : "column"} />
    </Container>
  );
};

export default Infos;
