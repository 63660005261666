import React, { useRef, useEffect } from "react";
import StyledComponent from "styled-components";

export default function Map() {
  return (
    <DivMap>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2628.324591993651!2d2.334442175967558!3d48.794783305181255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6713fc9e81275%3A0xa4388eb197e9aa2f!2s18%20Rue%20Coust%C3%A9%2C%2094230%20Cachan%2C%20France!5e0!3m2!1sen!2sdz!4v1684792641663!5m2!1sen!2sdz"
        width="100%"
        height="400px"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </DivMap>
  );
}

const DivMap = StyledComponent.div`
height : 400px;
display: flex;
padding: 5%;
align-items: center;
`;
