import React, { useRef, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
import styled from "styled-components";
import "swiper/css";
import Arrow from "./arrow.gif";
import pic1 from "./1.jpg";
import pic2 from "./2.jpg";
import pic3 from "./3.jpg";
import { Button, Typography } from "@mui/material";

const WhyUs = ({ Width }) => {
  // Styled Components
  const Container = styled.div`
    position: relative;
    height: 100%;
    font-family:
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
  `;

  const FirstText = styled.span`
    font-family: serif;
    font-size: 3em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(gold, gold);
    background-size: 100% 10px;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    transition:
      background-size 0.7s,
      background-position 0.5s ease-in-out;
    color: black;
    width: max-content;
    &:hover {
      background-size: 100% 100%;
      background-position: 0% 100%;
      transition:
        background-position 0.7s,
        background-size 0.5s ease-in-out;
    }
  `;

  const Img = styled.img`
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    filter: blur(2px) saturate(0.8) brightness(0.4);
  `;

  const Text = styled.div`
    position: absolute;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    max-width: ${Width > 700
      ? "65%"
      : "90%"}; /* adjusted max-width for better responsiveness */
  `;

  const swiperRef = useRef(null);
  const scrolledToSwiper = useRef(false);

  const handleScroll = useCallback(() => {
    if (!scrolledToSwiper.current && swiperRef.current) {
      const rect = swiperRef.current.getBoundingClientRect();
      const isInMiddle =
        rect.top < window.innerHeight / 2 &&
        rect.bottom > window.innerHeight / 2;
      if (isInMiddle) {
        swiperRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        scrolledToSwiper.current = true;
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleContactUsClick = useCallback(() => {
    const contactUsElement = document.getElementById("ContactUs");
    if (contactUsElement) {
      contactUsElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSwiper = (swiper) => {
    swiper.on("slideChange", () => {
      const WhyUsElement = document.getElementById("WhyUsID");
      if (WhyUsElement) {
        WhyUsElement.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  return (
    <Container id="WhyUsID">
      <Swiper className="mySwiper" ref={swiperRef} onSwiper={handleSwiper}>
        <SwiperSlide>
          <div style={{ position: "relative", width: "100%", height: "100vh" }}>
            <FirstText>Pourquoi nous ?</FirstText>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100vw", height: "100vh" }}>
          <Img src={pic1} />
          <Text>
            <Typography variant="h3" style={{ padding: "20px" }}>
              Un excellent service client
            </Typography>
            <Typography variant="body1" style={{ padding: "20px" }}>
              Nous plaçons nos clients au cœur de notre entreprise, en offrant
              un service chaleureux et respectueux, personnalisé à leurs
              besoins, pour des interactions mémorables et durables.
            </Typography>
          </Text>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100vw", height: "100vh" }}>
          <Img src={pic2} />
          <Text>
            <Typography variant="h3" style={{ padding: "20px" }}>
              Des professionnels
            </Typography>
            <Typography variant="body1" style={{ padding: "20px" }}>
              Nous comptons parmi notre équipe des experts de premier plan dans
              notre domaine, prêts à répondre à vos besoins avec excellence.
              Chaque membre est sélectionné avec soin pour assurer un service de
              qualité et se réjouit de collaborer avec vous.
            </Typography>
          </Text>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100vw", height: "100vh" }}>
          <Img src={pic3} />
          <Text>
            <Typography variant="h3" style={{ padding: "20px" }}>
              Qualité supérieure
            </Typography>
            <Typography variant="body1" style={{ padding: "20px" }}>
              Nous sommes convaincus de l'importance de l'excellence dès le
              premier coup, basant nos services sur des méthodes éprouvées. Avec
              notre équipe expérimentée, nous nous engageons à offrir une
              qualité inégalée dans notre industrie.
            </Typography>
          </Text>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100vw", height: "100vh" }}>
          <Text>
            <Typography
              variant="h3"
              style={{ padding: "20px", color: "black" }}
            >
              Cela vous intéresse ?
            </Typography>
            <Typography
              variant="body1"
              style={{ padding: "20px", color: "black" }}
            >
              Contactez-nous aujourd'hui pour échanger avec nos ingénieurs
              d'affaires qui trouverons pour vous les meilleurs consultants afin
              de répondre à vos besoins précis !
            </Typography>
            <Button
              size="large"
              variant="outlined"
              style={{ margin: "40px" }}
              onClick={handleContactUsClick}
            >
              Contactez-Nous
            </Button>
          </Text>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default WhyUs;
