import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./Pages/Home/Home";

const App = () => {
  // Screen Width with debounced resize
  function useWindowWidth() {
    const [Width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      let timeoutId;

      const handleResize = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setWidth(window.innerWidth);
        }, 200); // Adjust debounce delay as needed
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        clearTimeout(timeoutId);
      };
    }, []);

    return Width;
  }

  const Width = useWindowWidth();

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home Width={Width} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
