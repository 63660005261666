import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import styled from "styled-components"; // Import styled-components
import Button from "@mui/material/Button";
import emailjs from "@emailjs/browser";

const Form = () => {
  emailjs.init("i0pIiBp4-8AQX_Nwt"); // Initialize emailjs outside of useEffect

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [MessageSended, setMessageSended] = useState(false); // Use boolean for better control
  const [SendMessage, setSendMessage] = useState(true); // Set initial state to true to show the form

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_ux5fjnh", "template_30xfuoh", e.target, {
        publicKey: "Dhnq9ZmPxmVwMg_yY",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessageSended(true); // Update state to show confirmation message
          setSendMessage(false); // Update state to hide the form after sending
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <FormDiv>
      <form
        style={{
          display: SendMessage ? "flex" : "none",
          justifyContent: "flex-start",
        }}
        onSubmit={sendEmail}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "95%",
          }}
        >
          <Input>
            <TextField
              fullWidth
              required
              label="Nom et prénom"
              name="user_name" // Use name attribute for form submission
              value={Name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Input>
          <Input>
            <TextField
              fullWidth
              required
              label="Email"
              name="user_email" // Use name attribute for form submission
              value={Email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Input>
          <Input>
            <TextField
              fullWidth
              required
              label="Message"
              multiline
              rows={4}
              name="message" // Use name attribute for form submission
              value={Message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
          </Input>
          <Input>
            <Button variant="contained" type="submit">
              Envoyer
            </Button>
          </Input>
        </Box>
      </form>
      {MessageSended && (
        <p style={{ margin: "20px", padding: "20px" }}>
          Votre message a été reçu avec succès ! Nous vous répondrons dans les
          plus brefs délais.
          <br /> Equipe GOLDEN TECHNOLOGY.
        </p>
      )}
    </FormDiv>
  );
};

export default Form;

const Input = styled.div`
  margin: 10px;
`;

const FormDiv = styled.div``;
