import React from "react";
import { Typography } from "@mui/material";
import Form from "./Form";
import Map from "./Map";
import styled from "styled-components";

const ContactUs = ({ Width }) => {
  const Element = styled.div`
    padding: 15px;
    width: ${Width > 800 ? "45%" : "95%"};
  `;

  const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${Width > 800 ? "row" : "column"};
    justify-content: space-evenly;
    max-width: 1300px;
    margin: auto;
  `;
  return (
    <Container id="ContactUs">
      <Element>
        <Typography variant="h5" style={{ marginBottom: "10px" }}>
          Contactez-nous
        </Typography>
        <Typography variant="caption text" paragraph={true}>
          Vous avez une question ? Nous vous répondons ! Il vous suffit de
          remplir le formulaire de contact ci-dessous et nous reviendrons vers
          vous dans les plus brefs délais.
        </Typography>
        <Form />
      </Element>
      <Element>
        <Typography
          variant="h5"
          style={{ marginBottom: "10px", textAlign: "center" }}
        ></Typography>
        <Map />
      </Element>
    </Container>
  );
};

export default ContactUs;
