import React from "react";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import ferroviaire_img from "./ferroviaire_img.jpg";
import gestionDeProjet_img from "./gestion-de-projet_img.jpg";
import businessIntelligence_img from "./business-intelligence_img.jpg";
import bigData_img from "./big-data_img.jpg";
import devops_img from "./devops_img.jpg";

const Services = () => {
  const Cardslist = [
    { name: "ferroviaire", img: ferroviaire_img },
    { name: "gestion-de-projet", img: gestionDeProjet_img },
    { name: "business-intelligence", img: businessIntelligence_img },
    { name: "big_data", img: bigData_img },
    { name: "DevOps", img: devops_img },
  ];

  const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: center;
    flex-direction: column;
    margin: 15px;
  `;

  const CardsText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `;

  const Card = styled.div`
    width: 300px;
    height: 200px;
    border-radius: 0.6em;
    margin: 1em;
    overflow: hidden;
    cursor: pointer;
    box-shadow:
      0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
      0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    &:hover {
      transform: scale(1.03);
      box-shadow:
        0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
        0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
        0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    }
  `;

  const Cards = styled.div`
    display: flex;
    max-width: 115%;
    flex-wrap: wrap;
    justify-content: center;
  `;

  return (
    <div>
      <CardsContainer id="Services">
        <CardsText>
          <Typography variant="h5" style={{ marginBottom: "10px" }}>
            Nos différents services
          </Typography>
          <Typography variant="caption" paragraph>
            Une équipe de consultants et de collaborateurs de haut niveau, vous
            assure une efficacité d’intervention dans:
          </Typography>
        </CardsText>

        <Cards>
          {Cardslist.map((card) => (
            <Card
              style={{ backgroundImage: `url(${card.img})` }}
              key={card.name}
            >
              <Button style={{ width: "100%", height: "100%" }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "700", color: "white" }}
                >
                  {card.name.replaceAll("-", " ").toUpperCase()}
                </Typography>
              </Button>
            </Card>
          ))}
        </Cards>
      </CardsContainer>
    </div>
  );
};

export default Services;
