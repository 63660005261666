import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Background from "./background.gif";

const Welcome = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container showText={showText}>
      {showText && (
        <Text>
          <H1>Golden Technology</H1>
          <P>
            Éclairons l'avenir avec l'or de l'innovation, la technologie de la
            transformation.
          </P>
        </Text>
      )}
    </Container>
  );
};

export default Welcome;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(${Background}) no-repeat center center fixed;
  background-size: cover;
  filter: blur(${(props) => (props.showText ? 0 : 12)}px);
  transition: filter 1s ease; /* Add CSS transition for smoother blur effect */
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
`;

const H1 = styled.h1`
  font-size: 7vw;
  font-family: sans-serif;
  font-weight: 900;
  color: white;
  margin-bottom: 0;
`;

const P = styled.p`
  font-family: monospace;
  font-weight: 600;
  color: white;
  max-width: 80%;
  text-align: center;
`;
