import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import "./styles.css";
const Navbar = () => {
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "visible" : "hidden";
  return (
    <header className={cls}>
      <Container>
        <Logo src={logo} component={Link} to="/" />
      </Container>
    </header>
  );
};

export default Navbar;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: linear-gradient(#1e3e7b, #ff000000);
`;

const Logo = styled.img`
  height: 60px;
  width: auto;
  cursor: pointer;
  margin: 1%;
  margin-left: 20px;
`;
