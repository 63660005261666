import { Typography } from "@mui/material";
import React from "react";
import styledComponents from "styled-components";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  // Function to handle Twitter click event
  const handleTwitterClick = () => {
    window.open(
      "https://www.linkedin.com/company/axe-bi-consulting/",
      "_blank"
    );
  };

  // Function to handle Email click event
  const handleEmailClick = () => {
    window.location.href = "mailto:amrani@goldentechnology.fr";
  };

  // Function to handle Phone click event
  const handlePhoneClick = () => {
    window.location.href = "tel:+33980558214";
  };

  return (
    <FooterContainer>
      <FooterContainerTop>
        <FooterElement>
          <Typography variant="h5" style={{ marginBottom: "5px" }}>
            COORDONNES
          </Typography>
          <Typography
            variant="OVERLINE TEXT"
            style={{ display: "flex", alignItems: "flex-start", margin: "5px" }}
          >
            <FmdGoodIcon />
            &nbsp; 18 Rue Coustré <br />
            &nbsp; 94230
            <br />
            &nbsp; Cachan{" "}
          </Typography>
          <Typography
            variant="OVERLINE TEXT"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={handlePhoneClick} // Adding onClick event handler
          >
            <PhoneIcon /> &nbsp; +33 9 80 55 82 14{" "}
          </Typography>
          <Typography
            variant="OVERLINE TEXT"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={handleEmailClick} // Adding onClick event handler
          >
            <MailIcon /> &nbsp; amrani@goldentechnology.fr{" "}
          </Typography>
        </FooterElement>

        <FooterElement>
          <Typography variant="h5" style={{ marginBottom: "5px" }}>
            HEURES DE SERVICE
          </Typography>
          <Typography variant="body2">
            Lundi - Vendredi:<span> 9am - 5pm</span>
          </Typography>
          <Typography variant="body2">
            Samedi - Dimanche:<span> Fermé</span>
          </Typography>
        </FooterElement>

        <FooterElement>
          <Typography variant="h5" style={{ marginBottom: "5px" }}>
            NOUS SUIVRE
          </Typography>
          <Typography
            variant="OVERLINE TEXT"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={handleTwitterClick} // Adding onClick event handler
          >
            <LinkedInIcon /> &nbsp; GOLDEN_TECHNOLOGY{" "}
          </Typography>
        </FooterElement>
      </FooterContainerTop>

      <div
        style={{
          textAlign: "center",
          margin: "10px",
          color: "white",
          fontSize: "x-small",
        }}
      >
        &copy; Copyright 2024, GOLDEN TECHNOLOGY
      </div>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainerTop = styledComponents.div`
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const FooterContainer = styledComponents.div`
  background-color: #393939;
`;

const FooterElement = styledComponents.div` 
  width: fit-content;
  min-width: 260px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  color: white;
  margin: 20px;
`;
